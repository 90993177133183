import React, { createContext, useEffect, useState } from "react";
import superonLogo from "../../assets/superonlogo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../utils/config";
import { AuthContext, useAuth } from "../../context/UserContext";

import "./login.css";

function Loginpage({setRoleData}) {
  const {setIsLoggedIn} = useAuth()
  // const { isLoggedIn, setIsLoggedIn } = createContext(AuthContext);

  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("Login");

  const asyncLocalStorageSetItem = async (key, value) => {
    return new Promise((resolve, reject) => {
        try {
            localStorage.setItem(key, value);
            resolve(); // Resolve the promise
        } catch (error) {
            reject(error); // Reject if an error occurs
        }
    });
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(userDetails.email && userDetails.password)) {
      setMessage("Enter email and password!");
      setTimeout(() => {
        setMessage("");
      }, 2000);

      return;
    }
    console.log(userDetails);

    let data = {
      email: userDetails.email,
      password: userDetails.password,
    };

    try {
      setLoadingMessage("Please wait...");
      let response = await axios.post(
        config.ipAddress + `/admin/validateuser`,
        data
      );
      console.log('response',response?.data?.data?.user?.permission);


      if (response?.data?.data?.valid === 1) {
        
        // localStorage.setItem("superonAdmin", userDetails.email);
        // localStorage.setItem("role",response?.data?.data?.user?.permission)

        setRoleData(response?.data?.data?.user?.permission);

        await asyncLocalStorageSetItem("superonAdmin", userDetails.email);
        await asyncLocalStorageSetItem("role", JSON.stringify(response?.data?.data?.user?.permission));
    
        if(!localStorage.getItem("role")) {
          localStorage.removeItem("superonAdmin");
          navigate("/");
          return
        }

        setLoadingMessage("Login");
        // login()
        console.log('inside if ')
        // console.log('isLoggedIn', isLoggedIn)
        console.log('setIsLoggedIn', setIsLoggedIn)
        setIsLoggedIn(true)
        console.log('inside if 2')
        if(JSON.stringify(response?.data?.data?.user?.permission).includes('dashboard')) {
          navigate("/dashboard");
        }
        else {
          navigate("/report");
        }
        
      } else {
        // alert("Invalid Credentials");
        setMessage("Invalid Credentials");
        setLoadingMessage("Login");

        setTimeout(() => {
          setMessage("");
        }, 2000);
      }
    } catch (error) {
      setLoadingMessage("Login");
    }
  };

  useEffect(() => {
    let isValidate = localStorage.getItem("superonAdmin");
    if (!isValidate) {
      navigate("/");
    } else {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="h-screen flex justify-center items-center custom-bg">
      <main className="flex shadow-6xl rounded-lg bg-white absolute z-10 ">
        {/* left side */}
        <section className=" bg-login-pattern bg-no-repeat  bg-cover px-6 h-[530px]  w-[355px] rounded-tl-md rounded-bl-md">
          {/* <div className="mt-6 ">
            <img src={stanvacLogo} alt="stanvac-logo" className="w-[100px]" />
          </div> */}

          <div className="mt-[120px] ">
            <h1 className="text-[50px] text-white font-extrabold ">
              Welcome <br /> Back!
            </h1>
          </div>
        </section>

        {/* right side */}
        <section className="w-[355px]">
          <div className="mt-6 mr-6 flex justify-end">
            <img src={superonLogo} alt="stanvac-logo" className="w-[100px] " />
          </div>

          <div className="px-[40px] mt-10">
            <div>
              <h2 className="text-2xl font-extrabold">Login</h2>
              <p className="mt-2 text-md text-black">
                Welcome back! please login to your account
              </p>
            </div>

            <div className="my-4">
              <form action="" onSubmit={handleSubmit}>
                <label htmlFor="email" className="font-medium">
                  User Email
                </label>
                <br />
                <input
                  type="text"
                  placeholder="username@stanvac.com"
                  className="border px-2 py-2 mt-1 w-full rounded-[4px] placeholder:text-sm focus:outline-none focus:border-orange-light"
                  //   pattern="\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b"
                  value={userDetails.email}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, email: e.target.value })
                  }
                />

                <label
                  htmlFor="password"
                  className="font-medium inline-block mt-4 "
                >
                  Password
                </label>
                <br />
                <input
                  type="password"
                  placeholder="*************"
                  //   ref={inputPassRef}
                  className="border px-2 py-2 mt-1 w-full rounded-[4px] placeholder:text-sm focus:outline-none focus:border-orange-light"
                  //   pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{4,}$"
                  value={userDetails.password}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, password: e.target.value })
                  }
                />

                <div className="mt-4 flex justify-between items-center px-1">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="checkbox"
                      className="mr-2"
                      // onClick={showPassword}
                    />
                    <span className="text-sm">Remember me</span>
                  </div>

                  <div>
                    <Link className="text-blue-400 text-sm">
                      I forgot password
                    </Link>
                  </div>
                </div>

                <div>
                  <h1 className="text-center text-red-500 mt-1">{message}</h1>
                </div>

                <button
                  className="bg-orange-light w-full rounded-md   text-white  py-2 px-4  focus:outline-none focus:shadow-outline   block mx-auto mt-4"
                  type="submit"
                >
                  {loadingMessage}
                </button>
              </form>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Loginpage;
