import {useState,useEffect,useCallback} from 'react'
import Select from "react-select";
import axios from 'axios';
import config from "../utils/config";

function LeadModal({showModal,setShowModal,refreshLeads,setRefreshLeads}) {
  let adminEmail = localStorage.getItem("superonAdmin");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    state: "",
    city: "",
    assigneemobile: "",
    requirement: ""
  })
  const [errorObj, setErrorObj]=useState({
    name: false,
    email: false,
    mobile: false,
    state: false,
    city: false,
    assigneemobile: false,
    requirement: false
})
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const getAllEmployee = useCallback(async () => {
    let data = {
      email: adminEmail,
    };
    let response = await axios.post(config.ipAddress + `/employees`, data);

    console.log(response?.data?.data);
    let allEmployeeList = response?.data?.data;

    let uniqueEmployeeNames = new Set(); // Use a Set to store unique names
    let filteredEmployeeList = allEmployeeList.filter((employee) => {
      if (employee.name !== null && !uniqueEmployeeNames.has(employee.name)) {
        uniqueEmployeeNames.add(employee.name);
        return true;
      }
      return false;
    });

    let optionsArray = filteredEmployeeList.map((employee) => ({
      value: employee.mobile,
      label: employee.name,
    }));
    setOptions(optionsArray);
  }, []);

  useEffect(() => {
    getAllEmployee();
  }, []);
  const handleClose = ()=>{
    setErrorObj({
        name: false,
        email: false,
        mobile: false,
        state: false,
        city: false,
        assigneemobile: false,
        requirement: false
    })
    setFormData({
        name: "",
        email: "",
        mobile: "",
        state: "",
        city: "",
        assigneemobile: "",
        requirement: ""
      })
      setSelectedOption(null)
      setShowModal(false)
  }
  const handleSubmit = ()=>{
    let errorObj = {
        name: false,
        email: false,
        mobile: false,
        state: false,
        city: false,
        assigneemobile: false,
        requirement: false
    }
    if(!formData.name || !formData.email || !formData.mobile || !formData.state || !formData.city || !formData.assigneemobile || !formData.requirement){
        if(!formData.name){
         errorObj = {...errorObj,['name']:true}
        }
        if(!formData.email){
         errorObj = {...errorObj,['email']:true}
        }
        if(!formData.mobile){
            errorObj = {...errorObj,['mobile']:true}
           }
        if(!formData.state){
            errorObj = {...errorObj,['state']:true}
           }
        if(!formData.city){
            errorObj = {...errorObj,['city']:true}
           }
        if(!formData.assigneemobile){
            errorObj = {...errorObj,['assigneemobile']:true}
           }
        if(!formData.requirement){
            errorObj = {...errorObj,['requirement']:true}
           }
        setErrorObj({...errorObj})
        return
    }
    try{
        const res = axios.post(`${config.ipAddress}/submitlead`,formData)
        handleClose()
        setRefreshLeads(!refreshLeads)
    }catch(error){
        console.log(error)
    }
  }
console.log('formdata leads',formData)
    return (
        <>
          {showModal ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              >
                <div className="relative w-auto my-6 mx-auto">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-center p-5 rounded-t">
                      <h3 className="text-xl font-semibold text-center">
                        Create Lead
                      </h3>
                    
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto h-[50vh]">
                      <div className='lead-form-container'>
                        <div>
                       <div className='lead-item-container'>
                        <label>Employee</label>
                        <div className="w-[calc(25vw-1.5rem-120px)] relative">
                <Select
                  defaultValue={selectedOption}
                  onChange={(e)=>{
                    console.log('Employee',e)
                    setErrorObj({...errorObj,['assigneemobile']:false})
                    setFormData({...formData,['assigneemobile']:e?.value})
                    setSelectedOption(e)}}
                  options={options}
                  isMulti={false}
                  isSearchable
                  noOptionsMessage={() => "No employee found"}
                  placeholder={"Select Employee"}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      height:30,
                      boxShadow: "none",
                      border: "1px solid #ced4da",
                      outline: "orange",
                      marginBottom:0,
                      marginTop:0,
                      paddingTop:0,
                      paddingBottom:0,
                      fontSize: "14px",
                      borderColor: "#ced4da",
                      "&:hover": {
                        borderColor: "#f37022",
                      },
                    }),
                    valueContainer: (provided) => ({
                        ...provided,
                        padding: 0,
                        paddingLeft: '8px',
                        margin: 0,
                    }),
                    dropdownIndicator: () => ({
                      display: "none",
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    option: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: "#fff",
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "#f37022",
                        color: "#fff",
                      },
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "14px",
                      paddingTop:0,
                      paddingBottom:0,
                      marginTop:0,
                      marginBottom:0,
                    }),
                  }}
                />
              </div>
                       </div>
                       <div className='lead-item-container' style={{paddingTop:0,paddingBottom:0}}>
                        <label></label>
                        <span className='error-text'>{errorObj.assigneemobile ? "Required field" :""}</span>
                       </div>
                       </div>
                       <div>
                       <div className='lead-item-container'>
                        <label>Customer</label>
                        <input className='lead-input' 
                        placeholder='Customer Name' 
                        value={formData?.name}
                        onChange={(e)=>{
                            setErrorObj({...errorObj,['name']:false})
                            setFormData({...formData,['name']:e?.target?.value})}}/>
                       </div>
                       <div className='lead-item-container' style={{paddingTop:0,paddingBottom:0}}>
                        <label></label>
                        <span className='error-text'>{errorObj.name ? "Required field" :""}</span>
                       </div>
                       </div>
                       <div>
                       <div className='lead-item-container'>
                        <label>Email</label>
                        <input className='lead-input'
                        placeholder='Email' 
                        value={formData?.email}
                        onChange={(e)=>{
                            setErrorObj({...errorObj,['email']:false})
                            setFormData({...formData,['email']:e?.target?.value})}}/>
                       </div>
                       <div className='lead-item-container' style={{paddingTop:0,paddingBottom:0}}>
                        <label></label>
                        <span className='error-text'>{errorObj.email ? "Required field" :""}</span>
                       </div>
                       </div>
                       <div>
                       <div className='lead-item-container'>
                        <label>Mobile</label>
                        <input className='lead-input'
                        placeholder='Mobile' 
                        value={formData?.mobile}
                        onChange={(e)=>{
                            setErrorObj({...errorObj,['mobile']:false})
                            setFormData({...formData,['mobile']:e?.target?.value})}}/>
                       </div>
                       <div className='lead-item-container' style={{paddingTop:0,paddingBottom:0}}>
                        <label></label>
                        <span className='error-text'>{errorObj.mobile ? "Required field" :""}</span>
                       </div>
                       </div>
                       <div>
                       <div className='lead-item-container'>
                        <label>State</label>
                        <input className='lead-input'
                        placeholder='State' 
                        value={formData?.state}
                        onChange={(e)=>{
                            setErrorObj({...errorObj,['state']:false})
                            setFormData({...formData,['state']:e?.target?.value})}}/>
                       </div>
                       <div className='lead-item-container' style={{paddingTop:0,paddingBottom:0}}>
                        <label></label>
                        <span className='error-text'>{errorObj.state ? "Required field" :""}</span>
                       </div>
                       </div>
                       <div>
                       <div className='lead-item-container'>
                        <label>City</label>
                        <input className='lead-input'
                        placeholder='City' 
                        value={formData?.city}
                        onChange={(e)=>{
                            setErrorObj({...errorObj,['city']:false})
                            setFormData({...formData,['city']:e?.target?.value})}}/>
                       </div>
                       <div className='lead-item-container' style={{paddingTop:0,paddingBottom:0}}>
                        <label></label>
                        <span className='error-text'>{errorObj.city ? "Required field" :""}</span>
                       </div>
                       </div>
                       <div>
                       <div className='lead-item-container' style={{width:"calc(50vw - 3rem)"}}>
                        <label>Requirement</label>
                        <textarea 
                        placeholder='Requirement' 
                        value={formData?.requirement}
                        onChange={(e)=>{
                            setErrorObj({...errorObj,['requirement']:false})
                            setFormData({...formData,['requirement']:e?.target?.value})}}/>
                       </div>
                       <div className='lead-item-container' style={{paddingTop:0,paddingBottom:0}}>
                        <label></label>
                        <span className='error-text'>{errorObj.requirement ? "Required field" :""}</span>
                       </div>
                       </div>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 ">
                      <button
                        className="create-lead-btn" 
                        type="button"
                        onClick={() => handleClose()}
                      >
                        Close
                      </button>
                      <button
                        className="create-lead-btn ml-4" 
                        type="button"
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </>
      );
}

export default LeadModal