import React, { useCallback, useEffect, useRef, useState } from "react";
import Datepicker from "react-datepicker";
import Select from "react-select";
import Sidebar from "../sidebar/Sidebar";
import downloadIcon from "../../assets/download-icon.png";
import printIcon from "../../assets/print-icon.png";
import downloadLogo from "../../assets/downloadIcon.svg";
import submitLogo from "../../assets/icon-submit.svg";
import cancelLogo from "../../assets/cross.svg";
import downArrow from "../../assets/down-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";
import crossIcon from "../../assets/cross-small.svg";
import loadingIcon from "../../assets/spinner.gif";
import XLSX from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import "./request.css";
import Navbar from "../navbar/Navbar";
import axios, { all } from "axios";
import config from "../utils/config";
import { useNavigate } from "react-router-dom";
import loadingGif from "../../assets/loading2.gif";
import LeadModal from "./LeadModal";
import { saveAs } from "file-saver";
import Chip from "./Chip";
import { Drawer } from "@mui/material";
import SampleRequestActivity from "./SampleRequestActivity";
import StatusRemark from "./StatusRemark";
import DARRemark from "./DARRemark";
import DARActivity from "./DARActivity";
import SSLeadRemarkAdmin from "./SSLeadRemarkAdmin";

const selectCustomStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    borderBottom: "0px solid #ccc",
    borderRadius: "0",
    marginTop: "8px",
  }),
  menuList: (provided, state) => ({
    maxHeight: "150px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "10px",
      transition: "all linear 0.5s",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#bababa",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    height: "30px",
    width: "100%",
    lineHeight: "1",
    backgroundColor: state.isFocused ? "rgba(0,0,0,0.1)" : "white",
    color: state.isFocused ? "#000" : "black",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: state.isFocused ? "rgba(0,0,0,0.1)" : "#f2f2f2",
      color: state.isFocused ? "#000" : "black",
    },
  }),
  control: (base, state) => ({
    ...base,
    borderRadius: "none",
    border: "1px solid rgb(209 213 219)",
    // borderBottom: "1px solid #ccc",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid rgb(209 213 219)",
    },
    width: "100%",
    fontSize: "14px",
    color: "#000",
    borderRadius: "4px",
    height: "36px",
  }),
};
function Report() {
  let adminEmail = localStorage.getItem("superonAdmin");

  const navigate = useNavigate();

  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [allUserCopy, setAllUserCopy] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [employeeName, setEmployeeName] = useState("");
  const [employeeMobile, setEmployeeMobile] = useState("");
  const [divWidth, setDivWidth] = useState(null);
  const divRef = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [downloadList, setDownloadList] = useState([]);

  //
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [employeeDarList, setEmployeeDarList] = useState([]);
  const [employeeOdometerList, setEmployeeOdometerList] = useState([]);
  const [employeeLeaveList, setEmployeeLeaveList] = useState([]);
  const [employeeSampleList, setEmployeeList] = useState([]);
  const [employeeLedgerList, setEmployeeLedgerList] = useState([]);
  const [employeeLeadsList, setEmployeeLeadsList] = useState([]);
  const [employeeWeeklyList, setEmployeeWeeklyList] = useState([]);
  const [sampleRequestList, setSampleRequestList] = useState([]);
  const [openSSLeadsList, setOpenSSLeadsList] = useState([]);
  const [employeeOutputList, setEmployeeOutputList] = useState([]);
  //
  const [employeeNames, setEmployeeNames] = useState([]);
  const [employeePhNum, setEmployeePhNum] = useState([]);

  const [isattendanceChecked, setIsAttendanceChecked] = useState(false);
  const [isDarChecked, setIsDarChecked] = useState(false);
  const [isImprestChecked, setIsImprestChecked] = useState(false);
  const [isLeaveChecked, setIsLeaveChecked] = useState(false);
  const [isSampleChecked, setIsSampleChecked] = useState(false);
  const [isLedgerChecked, setIsLedgerChecked] = useState(false);
  const [isLeadsChecked, setIsLeadsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [refreshLeads, setRefreshLeads] = useState(false);

  const [tableId, setTableId] = useState(1);
  const [downloadName, setDownloadName] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [statusFilterOptions, setStatusFilterOptions] = useState([
    { label: "All", value: "0" },
    { label: "Open", value: "1" },
    { label: "Closed", value: "2" },
  ]);
  const [statusFilterVal, setStatusFilterVal] = useState("");

  const [isWeeklyPlanChecked,setIsWeeklyPlanChecked] = useState(false);
  const [isSampleRequestChecked,setIsSampleRequestChecked] = useState(false);
  const [isOutputDayChecked,setisOutputDayChecked] = useState(false);
  const [isOpenSSLeadsChecked,setIsOpenSSLeadsChecked] = useState(false);

  const [userLimit,setUserLimit] = useState("");
  const [sampleActivity,setSampleActivity] = useState(false);
  const [statusRemark,setStatusRemark] = useState(false);
  const [ssLeadRemark,setSSLeadRemark] = useState(false);
  const [ssLeadRemarkId,setSSLeadRemarkId] = useState(false);

  const [sampleId,setSampleId] = useState("");
  const [sampleObj,setSampleObj] = useState({});
  const [sampleName,setSampleName] = useState("");
  const [oldStatus,setOldStatus] = useState("");
  const [getNewStatus,setGetNewStatus] = useState("");

  const [visitId,setVisitId] = useState("");
  const [darRemark,setDarRemark] = useState(false);
  const [darActivity,setDarActivity] = useState(false);
  const [getDarNewStatus,setGetDarNewStatus] = useState("");
  const [darOldStatus,setDarOldStatus] = useState("");

  const [getActivityData,setGetActivityData] = useState([]);

  const role = JSON.parse(localStorage.getItem('role')) || []
  
  // handle user
  useEffect(() => {
    let isValidate = adminEmail;
    if (!isValidate) {
      navigate("/");
    }
  }, []);

  // table
  // const [showTableRow, setShowTableRow] = useState(true);

  // const getAllEmployee = async () => {
  //   // let data = {
  //   //   email: adminEmail,
  //   // };
  //   let response = await axios.post(config.ipAddress + `/employees`);

  //   // console.log(response?.data?.data);
  //   let allEmployeeList = response?.data?.data;
  //   let filteredEmployeeList = allEmployeeList.filter((employee) => {
  //     return employee.name !== null;
  //   });
  //   console.log(filteredEmployeeList);
  //   setAllUser(filteredEmployeeList);
  //   setAllUserCopy(filteredEmployeeList);

  //   for (let i = 0; i < filteredEmployeeList.length; i++) {
  //     let newObject = {
  //       value: filteredEmployeeList[i].mobile,
  //       label: filteredEmployeeList[i].name,
  //     };

  //     setOptions((prevArray) => [...prevArray, newObject]);
  //   }
  // };

  const getAllEmployee = useCallback(async () => {
    let data = {
      email: adminEmail,
    };
    let response = await axios.post(config.ipAddress + `/employees`, data);

    
    let allEmployeeList = response?.data?.data;

    let uniqueEmployeeNames = new Set(); // Use a Set to store unique names
    let filteredEmployeeList = allEmployeeList.filter((employee) => {
      if (employee.name !== null && !uniqueEmployeeNames.has(employee.name)) {
        uniqueEmployeeNames.add(employee.name);
        return true;
      }
      return false;
    });

    
    setAllUser(filteredEmployeeList);
    setAllUserCopy(filteredEmployeeList);

    let optionsArray = filteredEmployeeList.map((employee) => ({
      value: employee.mobile,
      label: employee.name,
    }));
    setOptions(optionsArray);
  }, []);

  const handleEmployeeChange = (e) => {
    setEmployeeName(e.target.value);

    let filteredList = allUserCopy.filter((item, index) => {
      // console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.name
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    setAllUser(filteredList);
  };

  const handleEmployeeName = (dealerName, mobile) => {
    // let namesArr = [];
    // let phArr = [];
    // namesArr.push(dealerName);
    // phArr.push(mobile);

    setEmployeeNames([...employeeNames, dealerName]);
    setEmployeePhNum([...employeePhNum, mobile]);
    
    setEmployeeName(dealerName);
    setEmployeeMobile(mobile);
    setShowEmployeeList(false);
  };

  const handleRemoveName = (indexOfName) => {
    let filteredNames = employeeNames.filter((name, index) => {
      return indexOfName !== index;
    });

    setEmployeeNames(filteredNames);
  };

  const covertDateString = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const outputString = `${year}-${month}-${day}`;

    

    return outputString;
  };
  const convertTime = (timeString) => {
    // const timeString = "11:32:32";
    if (!timeString) return null;
    const time = new Date(`2000-01-01T${timeString}`);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    // console.log(formattedTime); // Output: 11:32 AM
    return formattedTime;
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    // console.log(formattedDate);

    return formattedDate;
  };

  const convertIntoCamelCase = (name) => {
    if (!name) return;
    let formattedName = name.charAt(0) + name.slice(1).toLowerCase();

    return formattedName;
  };

  // convert name
  function formatName(name) {
    if (!name) return;

    const words = name.toLowerCase().split(" ");

    const formattedWords = words.map((word) => {
      if (word.length > 1) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word.toUpperCase();
      }
    });

    return formattedWords.join(" ");
  }

  const convertDistance = (distance) => {
    // console.log("runninggggggggg");
    // console.log(distance);
    if (!distance) return 0;

    let distanceNum = Number(distance);
    return distanceNum.toFixed(2);
  };
  // const names = [
  //   'Kouhsik saha',
  //   'Koushik kumar saha',
  //   'kouhsik b. kumar saha'
  // ];

  // const formattedNames = names.map((name) => formatName(name));
  // console.log(formattedNames);

  // handleAllClick 🚀🚀🚀
  const handleClick = () => {
    // if (isattendanceChecked) {
    //   getEmployeeDetails();
    //   return;
    // }

    // if (isDarChecked) {
    //   getEmployeeDarDetails();
    //   return;
    // }

    // if (isImprestChecked) {
    //   getEmployeeOdometerList();
    //   return;
    // }

    // if (isLeaveChecked) {
    //   getEmployeeLeaveList();
    //   return;
    // }

    // if (isLedgerChecked) {
    //   getLedgerList();
    //   return;
    // }
    // if (isSampleChecked) {
    //   getEmployeeSampleList();
    //   return;
    // }
    // setShowLoading(true);
    getEmployeeDetails();
    getEmployeeDarDetails();
    getEmployeeOdometerList();
    getEmployeeLeaveList();
    getLedgerList();
    getEmployeeSampleList();
    // setShowLoading(false);
  };
  const userEmailId = localStorage.getItem('superonAdmin');
  // Attendance 🚀🚀🚀
  const getEmployeeDetails = async () => {
    // if (!isattendanceChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length && phNumString.length < 10; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      // email: adminEmail,
    };
    
    // 9599227946,8619590275
    // let data = {
    //   mobile: "9599227946",
    //   fromdate: "2017-5-01",
    //   todate: "2023-5-18",
    // };
    

    try {
      let response = await axios.post(
        config.ipAddress + `/attendance-search`,
        {...data,email:userEmailId}
      );

      let allData = response?.data?.data;
      // allData.sort((a, b) => {
      //   const dateA = new Date(
      //     a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );
      //   const dateB = new Date(
      //     b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );

      //   if (dateA > dateB) {
      //     return -1;
      //   } else if (dateA < dateB) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });

      allData.sort((a, b) => {
        const dateA = new Date(
          a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );
        const dateB = new Date(
          b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );

        // First, compare by date
        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          // If the dates are equal, compare by name
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          } else {
            return 0;
          }
        }
      });

      
      setSearchEmployeeList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(1);
      setDownloadName("Attendance");
    } catch (error) {
      
      setShowLoading(false);
    }
  };

  // DAR 🚀🚀🚀
  const getEmployeeDarDetails = async () => {
    // if (!isDarChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");
    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      // email: adminEmail,
    };
    // 9599227946,8619590275
    // let data = {
    //   mobile: "9599227946",
    //   fromdate: "2017-5-01",
    //   todate: "2023-5-18",
    // };
    

    try {
      let response = await axios.post(config.ipAddress + `/dar-search`, {...data,email:userEmailId});

      let allData = response?.data?.data;

      allData.sort((a, b) => {
        const dateA = new Date(
          a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );
        const dateB = new Date(
          b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );

        // First, compare by date
        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          // If the dates are equal, compare by name
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          } else {
            return 0;
          }
        }
      });

      
      // console.log(allData);
      setEmployeeDarList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(2);
      setDownloadName("Dar");
    } catch (error) {
      
      setShowLoading(false);
    }
  };

  // Odometer 🚀🚀🚀

  const getEmployeeOdometerList = async () => {
    // if (!isImprestChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      // email: adminEmail,
    };

    try {
      let response = await axios.post(
        config.ipAddress + `/odometer-search-new`,
        {...data,email:userEmailId}
      );

      let allData = response?.data?.data;
      // allData.sort((a, b) => {
      //   const dateA = new Date(
      //     a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );
      //   const dateB = new Date(
      //     b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );

      //   if (dateA > dateB) {
      //     return -1;
      //   } else if (dateA < dateB) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
      
      // console.log(allData);
      setEmployeeOdometerList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(3);
      setDownloadName("Odometer");
    } catch (error) {
      
      setShowLoading(false);
    }
  };

  // odometer total reading
  const getOdoTotalReading = (punchIn, punchOut) => {
    if (punchIn === punchOut) return 0;
    let from = Number(punchIn);
    let to = Number(punchOut);
    return (to - from).toFixed(2);
  };

  const getPunchOutDetails = (
    punchInId,
    punchOutId,
    punchOutReading,
    punchOutImage,
    punchOutRemark
  ) => {
    let punchOutValue;
    let punchOutImageUrl;
    let punchOutRemarkUpdated;
    if (punchInId === punchOutId) {
      punchOutValue = "not created";
      punchOutImageUrl = "";
      punchOutRemarkUpdated = "not created";
      return { punchOutValue, punchOutImageUrl, punchOutRemarkUpdated };
    } else {
      punchOutValue = punchOutReading;
      punchOutImageUrl = config.ipAddress + punchOutImage;
      punchOutRemarkUpdated = punchOutRemark;
    }
    return { punchOutValue, punchOutImageUrl, punchOutRemarkUpdated };
  };

  const handleOdometerDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-GB");
    return formattedDate;
  };

  // Leave 🚀🚀🚀

  const getEmployeeLeaveList = async () => {
    // if (!isLeaveChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      // email: adminEmail,
    };

    try {
      let response = await axios.post(config.ipAddress + `/leave-search`, {...data,email:userEmailId});

      let allData = response?.data?.data;
      // allData.sort((a, b) => {
      //   const dateA = new Date(
      //     a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );
      //   const dateB = new Date(
      //     b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );

      //   if (dateA > dateB) {
      //     return -1;
      //   } else if (dateA < dateB) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
      
      // console.log(allData);
      setEmployeeLeaveList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(4);
      setDownloadName("Leave");
    } catch (error) {
      
      setShowLoading(false);
    }
  };

  // Sample/Catlogue 🚀🚀🚀

  const getEmployeeSampleList = async () => {
    // if (!isSampleChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      // email: adminEmail,
    };

    try {
      let response = await axios.post(
        config.ipAddress + `/catalogue-search`,
        data
      );

      let allData = response?.data?.data;
      // allData.sort((a, b) => {
      //   const dateA = new Date(
      //     a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );
      //   const dateB = new Date(
      //     b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );

      //   if (dateA > dateB) {
      //     return -1;
      //   } else if (dateA < dateB) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
      
      // console.log(allData);
      setEmployeeList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(5);
      setDownloadName("Sample");
    } catch (error) {
      
      setShowLoading(false);
    }
  };

  // Employee Output 🚀🚀🚀

  const getEmployeeOutputList = async () => {
    // if (!isImprestChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      // email: adminEmail,
    };

    try {
      let response = await axios.post(
        config.ipAddress + `/ss-outputday-search-new`,
        {...data,email:userEmailId}
      );

      let allData = response?.data?.data;
      
      // console.log(allData);
      setEmployeeOutputList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(10);
      setDownloadName("Output of the day");
    } catch (error) {
      
      setShowLoading(false);
    }
  };

  // ledger List 🚀🚀🚀🚀
  const getLedgerList = async () => {
    // if (!isLedgerChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      // email: adminEmail,
    };

    try {
      let response = await axios.post(
        config.ipAddress + `/ledger-search`,
        {...data,email:userEmailId}
      );

      let allData = response?.data?.data;
      // allData.sort((a, b) => {
      //   const dateA = new Date(
      //     a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );
      //   const dateB = new Date(
      //     b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );

      //   if (dateA > dateB) {
      //     return -1;
      //   } else if (dateA < dateB) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
      
      // console.log(allData);
      setEmployeeLedgerList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(6);
      setDownloadName("Ledger");
    } catch (error) {
      
      setShowLoading(false);
    }
  };

    // leads List 🚀🚀🚀🚀
    const getLeadsList = async (status) => {
      // if (!isLedgerChecked) return alert("Please check at least one box!");
  
      if (!(selectedDateStart && selectedDateEnd))
        return alert("Please enter all details!");
  
      setShowLoading(true);
  
      let phNumString = [];
      if (selectedOption) {
        for (let i = 0; i < selectedOption.length; i++) {
          phNumString.push(selectedOption[i].value);
        }
      }
      // console.log(phNumString);
  
      let data = {
        mobile: phNumString.join(","),
        fromdate: covertDateString(selectedDateStart),
        todate: covertDateString(selectedDateEnd),
        // email: adminEmail,
      };
  
      try {
        let response = await axios.post(
          config.ipAddress + `/leads`,
          data
        );
  
        let allData = response?.data?.data;
        
        if(status && status!=0){
          const filteredData = allData?.filter(item=>item?.extra5===status)
          setEmployeeLeadsList(filteredData);
          setDownloadList(filteredData);
        }else{
          setEmployeeLeadsList(allData);
          setDownloadList(allData);
        }
       
        setShowLoading(false);
        setTableId(7);
        setDownloadName("Leads");
      } catch (error) {
        
        setShowLoading(false);
      }
    };

  // Weekly Plan 🚀🚀🚀
  const getEmployeeWeekPlanDetails = async () => {
    // if (!isDarChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");
    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      // email: adminEmail,
    };
    // 9599227946,8619590275
    // let data = {
    //   mobile: "9599227946",
    //   fromdate: "2017-5-01",
    //   todate: "2023-5-18",
    // };
    

    try {
      let response = await axios.post(config.ipAddress + `/weekly-search`, {...data,email:userEmailId});

      let allData = response?.data?.data;

      
      // console.log(allData);
      setEmployeeWeeklyList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(8);
      setDownloadName("Weekly Plan");
    } catch (error) {
      
      setShowLoading(false);
    }
  };

  // Sample Request 🚀🚀🚀
  const getSampleRequestDetails = async () => {
    // if (!isDarChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");
    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // if (selectedOption) {
    //   for (let i = 0; i < selectedOption.length && phNumString.length < 10; i++) {
    //     phNumString.push(selectedOption[i].value);
    //   }
    // }
    

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      // email: adminEmail,
    };
    // 9599227946,8619590275
    // let data = {
    //   mobile: "9599227946",
    //   fromdate: "2017-5-01",
    //   todate: "2023-5-18",
    // };
    

    try {
      let response = await axios.post(config.ipAddress + `/sample-search`, {...data,email:userEmailId});

      let allData = response?.data?.data;

      
      // console.log(allData);
      setSampleRequestList(allData);
      // setDownloadList(allData);
      setShowLoading(false);
      setTableId(8);
      setDownloadName("Sample Request");
    } catch (error) {
      
      setShowLoading(false);
    }
  };

  // Open SS Leads Request 🚀🚀🚀
  const getOpenSSLeadsDetails = async () => {
    // if (!isDarChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");
    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      // email: adminEmail,
    };
    try {
      let response = await axios.post(config.ipAddress + `/opensslead-dar-search`, data);
      let allData = response?.data?.data;
      // console.log('competitor data',allData);
      setOpenSSLeadsList(allData);
      // setDownloadList(allData);
      setShowLoading(false);
      // setTableId(8);
      // setDownloadName("Sample Request");
    } catch (error) {
      
      setShowLoading(false);
    }
  };

  // Function to flatten nested array object data
  // const flattenData = (data) => {
  //   const flattenedData = [];

  //   const flatten = (obj, prefix = "") => {
  //     for (let key in obj) {
  //       if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
  //         flatten(obj[key], `${prefix}${key}.`);
  //       } else if (Array.isArray(obj[key])) {
  //         obj[key].forEach((item, index) => {
  //           flatten(item, `${prefix}${key}[${index}].`);
  //         });
  //       } else {
  //         flattenedData.push({
  //           key: `${prefix}${key}`,
  //           value: obj[key],
  //         });
  //       }
  //     }
  //   };

  //   flatten(data);

  //   return flattenedData;
  // };

  // Download Data 🚀🚀🚀
  // const downloadData = (table) => {
  //   if (!downloadList.length) return;

  //   // if (isDarChecked) {
  //   //   tableToExcel(table);
  //   //   return;
  //   // }

  //   const xlsx = require("xlsx");
  //   const workbook = xlsx.utils.book_new();

  //   let worksheet = xlsx.utils.json_to_sheet(downloadList.flat(10));
  //   xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   const excelFileBuffer = xlsx.write(workbook, {
  //     type: "buffer",
  //     bookType: "xlsx",
  //   });

  //   const blob = new Blob([excelFileBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "data.xlsx";
  //   link.click();
  // };

  // dar data download 🚀🚀🚀
  let tableToExcel = (function () {
    let uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
    return function (table, name) {
      
      if (!table.nodeType) table = document.getElementById(table);
      let ctx = { worksheet: name || "Worksheet", table: table.innerHTML };
      // window.location.href = uri + base64(format(template, ctx));
      let excelData  = uri + base64(format(template, ctx));

      // Create a temporary link to trigger download
      let link = document.createElement("a");
      link.href = excelData;
      link.download = name+" TableData" || "download.xlsx"; // Set the file name here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM
    };
  })();

  // downlaod table data 🚀🚀🚀🚀🚀🚀
  function downloadTableData(table) {
    
    if (isDarChecked) {
      tableToExcel(table, "DAR");
      return;
    }
    if (isWeeklyPlanChecked) {
      tableToExcel("tableId8", "Weekly Plan");
      return;
    }
    if (isSampleRequestChecked) {
      tableToExcel("tableId9", "Sample Request");
      return;
    }
    const xlsx = require("xlsx");

    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.table_to_sheet(
      document.getElementById(`tableId${tableId}`)
    );
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `${downloadName}TableData.xlsx`);
  }

  const handleAttendanceChange = (e) => {
    setIsAttendanceChecked(e.target.checked);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsSampleChecked(false);
    setIsLedgerChecked(false);
    setIsLeadsChecked(false);
    setIsWeeklyPlanChecked(false);
    setIsSampleRequestChecked(false);
    setisOutputDayChecked(false);
  };
  const handleDarChange = (e) => {
    setIsDarChecked(e.target.checked);
    setIsAttendanceChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsSampleChecked(false);
    setIsLedgerChecked(false);
    setIsLeadsChecked(false);
    setIsWeeklyPlanChecked(false);
    setIsSampleRequestChecked(false);
    setisOutputDayChecked(false);
    setIsOpenSSLeadsChecked(false);
  };
  
  const handleImprestChange = (e) => {
    setIsImprestChecked(e.target.checked);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsLeaveChecked(false);
    setIsSampleChecked(false);
    setIsLedgerChecked(false);
    setIsLeadsChecked(false);
    setIsWeeklyPlanChecked(false);
    setIsSampleRequestChecked(false);
    setisOutputDayChecked(false);
    setIsOpenSSLeadsChecked(false);
  };

  const handleLeaveChange = (e) => {
    setIsLeaveChecked(e.target.checked);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsSampleChecked(false);
    setIsLedgerChecked(false);
    setIsLeadsChecked(false);
    setIsWeeklyPlanChecked(false);
    setIsSampleRequestChecked(false);
    setisOutputDayChecked(false);
    setIsOpenSSLeadsChecked(false);
  };

  const handleSampleChange = (e) => {
    setIsSampleChecked(e.target.checked);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsLedgerChecked(false);
    setIsLeadsChecked(false);
    setIsWeeklyPlanChecked(false);
    setIsSampleRequestChecked(false);
    setisOutputDayChecked(false);
    setIsOpenSSLeadsChecked(false);
  };

  const handleLedgerChange = (e) => {
    setIsLedgerChecked(e.target.checked);
    setIsSampleChecked(false);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsLeadsChecked(false);
    setIsWeeklyPlanChecked(false);
    setIsSampleRequestChecked(false);
    setisOutputDayChecked(false);
    setIsOpenSSLeadsChecked(false);
  };
  const handleLeadsChange = (e) => {
    setIsLeadsChecked(e.target.checked);
    setIsLedgerChecked(false);
    setIsSampleChecked(false);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsWeeklyPlanChecked(false);
    setIsSampleRequestChecked(false);
    setisOutputDayChecked(false);
    setIsOpenSSLeadsChecked(false);
  };
  const handleWeeklyPlan = (e) => {
    setIsWeeklyPlanChecked(e.target.checked)
    setIsLeadsChecked(false);
    setIsLedgerChecked(false);
    setIsSampleChecked(false);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsSampleRequestChecked(false);
    setisOutputDayChecked(false);
    setIsOpenSSLeadsChecked(false);
  }
  const handleSampleRequest = (e) => {
    setIsSampleRequestChecked(e.target.checked)
    setIsWeeklyPlanChecked(false)
    setIsLeadsChecked(false);
    setIsLedgerChecked(false);
    setIsSampleChecked(false);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setisOutputDayChecked(false);
    setIsOpenSSLeadsChecked(false);
  }
  const handleOutputDay = (e) => {
    setisOutputDayChecked(e.target.checked);
    setIsWeeklyPlanChecked(false)
    setIsLeadsChecked(false);
    setIsLedgerChecked(false);
    setIsSampleChecked(false);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsSampleRequestChecked(false);
    setIsOpenSSLeadsChecked(false);
  }
  const handleOpenSSLeads = (e) => {
    setIsOpenSSLeadsChecked(e.target.checked);
    setisOutputDayChecked(false);
    setIsWeeklyPlanChecked(false)
    setIsLeadsChecked(false);
    setIsLedgerChecked(false);
    setIsSampleChecked(false);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsSampleRequestChecked(false)
  }
  const handleChange = (selected) => {
    if (selected.length <= 5) {
      setSelectedOption(selected);
    } else {
      setUserLimit("You can select up to 5 users only.")
      // alert("You can select up to 10 options only.");
      setTimeout(()=>{
        setUserLimit("")
      },5000)
    }
  };
  const handleStartDateChange = (date) => {
    const currentDate = new Date();
    let endDate = new Date(date);
    
    // Add 10 days to start date
    endDate.setDate(endDate.getDate() + 6);

    // If the end date exceeds the current date, set it to current date
    if (endDate > currentDate) {
      endDate = currentDate;
    }

    setSelectedDateStart(date);
    setSelectedDateEnd(endDate); // Auto-select end date
  };

  const handleEndDateChange = (date) => {
    const currentDate = new Date();
    const maxEndDate = new Date(selectedDateStart);
    maxEndDate.setDate(maxEndDate.getDate() + 10); // Add 10 days to start date

    // Ensure selected end date is not beyond the 10-day range or current date
    if (date <= maxEndDate && date <= currentDate) {
      setSelectedDateEnd(date);
    } else {
      alert("End date must be within 10 days of the start date and not in the future.");
    }
  };
  useEffect(() => {
    getAllEmployee();
    // getEmployeeDetails();
  }, []);

  useEffect(() => {
    // handleClick();
    if (isattendanceChecked) {
      getEmployeeDetails();
      return;
    }

    if (isDarChecked) {
      
      if(selectedOption?.length) {
        getEmployeeDarDetails();
      }
      setEmployeeDarList([]);
      return;
    }

    if (isImprestChecked) {
      if(selectedOption?.length) {
        getEmployeeOdometerList();
      }
      setEmployeeOdometerList([]);
      return;
    }

    if (isLeaveChecked) {
      getEmployeeLeaveList();
      return;
    }

    if (isLedgerChecked) {
      getLedgerList();
      return;
    }
    if (isSampleChecked) {
      getEmployeeSampleList();
      return;
    }
    if (isOutputDayChecked) {
      // if(selectedOption?.length) {
        getEmployeeOutputList();
      // }
      setEmployeeOutputList([]);
      return;
    }
    if (isLeadsChecked) {
      getLeadsList(statusFilterVal);
      return;
    }
    if (isWeeklyPlanChecked) {
      getEmployeeWeekPlanDetails();
      return;
    }
    if (isSampleRequestChecked) {
      getSampleRequestDetails();
      return;
    }
    if (isOpenSSLeadsChecked) {
      getOpenSSLeadsDetails();
      return;
    }
  }, [
    selectedOption,
    isattendanceChecked,
    isDarChecked,
    isImprestChecked,
    isLeaveChecked,
    isSampleChecked,
    isLedgerChecked,
    isLeadsChecked,
    isWeeklyPlanChecked,
    isSampleRequestChecked,
    isOpenSSLeadsChecked,
    isOutputDayChecked,
    selectedDateStart,
    selectedDateEnd,
    statusFilterVal,
    refreshLeads
  ]);

  

  const attendanceVisit = async (e,date,mobile) => {
    // e.preventDefault();
    
    const payload = {
      mobile: mobile,
      visitdate: date.replace(/-0+/g, '-')
    }
    
    const res = await axios.post(config.ipAddress + `/visitbydate`, payload)
    

    const update = searchEmployeeList?.map((item)=>{
      if(item?.mobile == mobile && item?.attendancedate == date) {
        return {
          ...item,
          visitCount: res?.data?.data?.length + 1
        }
      }
      return item
    })
    
    
    setSearchEmployeeList(update)
  }
  

  const closeForm = () => {
    setSampleActivity(false)
    setDarActivity(false)
    setStatusRemark(false)
    setDarRemark(false)
    setSSLeadRemark(false)
  }

  const updateStatus = (id,status,sampleObj) => {
    setStatusRemark(true)
    setSampleId(id)
    setSampleObj(
      sampleObj.map((item) => {
        if (item.itemid === id) {
          return {
            ...item,
            isChecked: true
          }
        } else{

          return {
            ...item,
            isChecked: false
          }
        }
      })
    )
    setOldStatus(status)
  }

  const updateSSLeadStatus = (id) => {
    setSSLeadRemark(true)
    setSSLeadRemarkId(id)
  }

  const activityViewFun = async (id, itemDesc) => {
    const res = await axios.post(config.ipAddress + `/getsampleactivity`, {itemid:id})
    const getRes = res?.data?.data?.activities
    setGetActivityData(getRes)
    setSampleId(id)
    setSampleName(itemDesc)
    setSampleActivity(true)
  }

  const allActivity = async ()=> {
    const tempId = sampleObj?.map((item)=> {
        return item.itemid
    })
    const res = await axios.post(config.ipAddress + `/getsampleactivitynew`, {itemid:tempId})
    const getRes = res?.data?.data
    setGetActivityData(getRes)
    // setActivityId(tempId)
    setSampleActivity(true)
}

  const updateDarStatus = (id,status) => {
    setDarRemark(true)
    setVisitId(id)
    setDarOldStatus(status)
    // setStatusRemark(true)
    // setSampleId(id)
    // setOldStatus(status)
  }
  console.log('dar status update',getDarNewStatus)
  console.log('dar Oldstatus update',darOldStatus)
  const activityDarViewFun = (id, itemDesc) => {
    setDarActivity(true)
    setVisitId(id)
    // setSampleName(itemDesc)
  }

  // const updateDarVisit = (id,status) => {
  // const tempArray =  employeeDarList?.map((item,index) => {
  //   const tempVisit = item?.visits?.map((visit) => {
  //     if(visit?.visitid == id) {
  //       alert('enter visit')
  //       return {
  //         ...visit,
  //         status: status
  //       }
  //     }
  //     return visit
  //   })
    
  //   setEmployeeDarList({
  //     ...employeeDarList,
  //     visits: tempVisit
  //   })
      
  //   })
  //   console.log('temp array', tempArray)
  // };

  const updateDarVisit = (id, status) => {
    const updatedList = employeeDarList?.map((item) => {
      const updatedVisits = item?.visits?.map((visit) => {
        if (visit?.visitid === id) {
          // alert("Enter visit");
          return {
            ...visit,
            status: status,
          };
        }
        return visit;
      });
  
      return {
        ...item,
        visits: updatedVisits,
      };
    });
  
    setEmployeeDarList(updatedList); // Update the state with the modified list
    console.log("Updated List", updatedList);
  };

  useEffect(()=>{
    
    if(role.includes("dashboard")) {
      setIsAttendanceChecked(true)
    }
    if (role.includes("sample-request") && !role.includes("dashboard")) {
      setIsAttendanceChecked(false)
      setIsSampleRequestChecked(true)
    }
  },[])
  
  useEffect(()=>{
    setOldStatus(getNewStatus)
    getSampleRequestDetails()
  },[getNewStatus]);

  // useEffect(()=>{
  //   setDarOldStatus(getDarNewStatus)
  //   getEmployeeDarDetails()
  // },[getDarNewStatus]);
  console.log('emp sddf',employeeDarList)
  return (
    <div>
      <div className=" bg-[#fafafa]">
        {/* <div className="w-[18%]">
          <Sidebar />
        </div> */}
        <div className="sticky top-0 left-0 z-[3]">
          <Navbar />
        </div>
        <section className="w-full bg-[#fff] px-10 pt-4">
          <main className="bg-white w-full shadow-6xl rounded-[10px] py-4 border-[1px] border-gray-300">
            <div className="flex justify-between items-center px-4">
            <div
              // onClick={() => setShowEmployeeList(false)}
              className="flex gap-2 mb-2"
            >
              <div className="bg-orange-light w-[2.5px] rounded-full"></div>
              <div className=" ">
                <h1 className="text-lg font-semibold">Add Request</h1>
              </div>
            </div>
            {isLeadsChecked ? <div className="flex">
              <div style={{ width: "136px" }}>
                <Select
                  styles={selectCustomStyles}
                  onChange={(e) => {
                    setStatusFilterVal(e?.value);
                  }}
                  options={statusFilterOptions}
                  placeholder={
                    <div style={{ fontSize: "12px" }}>Filter by status</div>
                  }
                />
              </div> 
              <button className="create-lead-btn ml-2" onClick={()=>setShowModal(!showModal)}>Create Lead</button>
              </div>
              : null}
            {/* {isLeadsChecked ? <button className="create-lead-btn" onClick={()=>setShowModal(!showModal)}>Create Lead</button> : null} */}
            </div>
            <div className=" py-4 px-4 w-full flex gap-5 ">
              {/* <form
                action=""
                className="flex gap-x-5 w-full justify-between items-center"
              > */}
              <div className="w-[60%] relative">
                <Select
                  defaultValue={selectedOption}
                  // onChange={setSelectedOption}
                  onChange={handleChange}
                  options={options}
                  isMulti
                  isSearchable
                  noOptionsMessage={() => "No employee found"}
                  placeholder={"Select Employee"}
                  value={selectedOption}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: "none",

                      height: "100%",
                      // padding: "5px 0px",
                      border: "1px solid #ced4da",
                      // borderColor: "gray",
                      // borderColor: state.isFocused
                      //   ? "1px solid orange"
                      //   : "gray",
                      outline: "orange",

                      borderColor: "#ced4da",
                      "&:hover": {
                        borderColor: "#f37022",
                      },
                    }),

                    dropdownIndicator: () => ({
                      display: "none",
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),

                    option: (baseStyles) => ({
                      ...baseStyles,
                      //   backgroundColor: "orange",
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#f37022",
                        color: "#fff",
                      },
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                  }}
                />
                <p className="text-[12px] text-[#f00]">{userLimit}</p>
              </div>

              <div className="w-[30%] flex  gap-3">
                <Datepicker
                  // portalId="root-portal"
                  // popperClassName="some-custom-class"
                  popperPlacement="bottom"
                  // popperModifiers={[
                  //   {
                  //     name: "offset",
                  //     options: {
                  //       offset: [5, 10],
                  //     },
                  //   },
                  //   {
                  //     name: "preventOverflow",
                  //     options: {
                  //       rootBoundary: "viewport",
                  //       tether: false,
                  //       altAxis: true,
                  //     },
                  //   },
                  // ]}
                  selected={selectedDateStart}
                  // selected={new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)}
                  // onChange={(date) => setSelectedDateStart(date)}
                  onChange={handleStartDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="From"
                  // showYearDropdown
                  // minDate={new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)}
                  maxDate={new Date()} // Prevent future dates for start date
                  wrapperClassName="datePicker"
                ></Datepicker>
                
                
                <Datepicker
                  // portalId="root-portal"
                  // popperClassName="some-custom-class"
                  popperPlacement="bottom"
                  // popperModifiers={[
                  //   {
                  //     name: "offset",
                  //     options: {
                  //       offset: [5, 10],
                  //     },
                  //   },
                  //   {
                  //     name: "preventOverflow",
                  //     options: {
                  //       rootBoundary: "viewport",
                  //       tether: false,
                  //       altAxis: true,
                  //     },
                  //   },
                  // ]}
                  selected={selectedDateEnd}
                  onChange={(date) => setSelectedDateEnd(date)}
                  // onChange={handleEndDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="To"
                  // showYearDropdown
                  minDate={selectedDateStart} // Prevent selecting a date before start date
                  maxDate={
                    selectedDateStart ?
                    // new Date(selectedDateStart.getTime() + 6 * 24 * 60 * 60 * 1000)
                    new Date(
                      Math.min(
                        new Date().getTime(), // Current date's timestamp
                        selectedDateStart.getTime() + 6 * 24 * 60 * 60 * 1000 // 6 days after start date (so start + 7 days)
                      )
                    )
                    :
                    null} // Set max end date 7 days after start date
                  // maxDate={new Date()} // Ensure the end date cannot be in the future
                  wrapperClassName="datePicker"
                ></Datepicker>
              </div>
              <div className="flex gap-1 items-center">
                {/* <div className=" flex justify-end   w-[35px] h-[25px]  rounded-[4px]">
                  <img
                    src={submitLogo}
                    alt="submit-logo"
                    className="w-[20px] mx-auto cursor-pointer"
                    onClick={() => handleClick()}
                  />
                </div> */}
                <img
                  src={downloadLogo}
                  title="Download"
                  alt="download-logo"
                  className="w-5 cursor-pointer"
                  onClick={() => downloadTableData("tableId2")}
                />
              </div>

              {/* </form> */}
            </div>

            {/* employee names */}
            <div
              className={`flex flex-wrap gap-2 px-5 ${
                employeeNames.length ? " border-l-gray-400" : ""
              } px-2 `}
            >
              {employeeNames.length
                ? employeeNames.map((name, index) => {
                    return (
                      <div className="flex items-center">
                        <p key={index} className="text-sm text-green-500 ">
                          {name}
                        </p>
                        <img
                          src={crossIcon}
                          alt="cross-icon"
                          className="w-5 cursor-pointer"
                          onClick={() => handleRemoveName(index)}
                        />
                      </div>
                    );
                  })
                : ""}
            </div>
            <div>
              <div className="flex gap-12 px-4 py-3 ">
                {role.includes("attendance") &&
                  <div className="">
                    <input
                      type="checkbox"
                      name="checkbox"
                      className="mr-2 bg-black"
                      // value={attendance}
                      checked={isattendanceChecked}
                      onChange={(e) => handleAttendanceChange(e)}
                    />
                    <span>Attendance</span>
                  </div>
                }
                {role.includes("dar") &&
                  <div className="">
                    <input
                      checked={isDarChecked}
                      onChange={(e) => handleDarChange(e)}
                      type="checkbox"
                      name="checkbox"
                      className="mr-2"
                    />
                    <span>DAR</span>
                  </div>
                }
                {role.includes("odometer") &&
                  <div className="">
                    <input
                      checked={isImprestChecked}
                      onChange={(e) => handleImprestChange(e)}
                      type="checkbox"
                      name="checkbox"
                      className="mr-2"
                    />
                    <span>Odometer</span>
                  </div>
                }
                {role.includes("leave") &&
                  <div className="">
                    <input
                      checked={isLeaveChecked}
                      onChange={(e) => handleLeaveChange(e)}
                      type="checkbox"
                      name="checkbox"
                      className="mr-2"
                    />
                    <span>Leave</span>
                  </div>
                }
                {/* <div className="">
                  <input
                    checked={isSampleChecked}
                    onChange={(e) => handleSampleChange(e)}
                    type="checkbox"
                    name="checkbox"
                    className="mr-2"
                  />
                  <span>Sample</span>
                </div> */}
                {role.includes("ledger") &&
                  <div className="">
                    <input
                      checked={isLedgerChecked}
                      onChange={(e) => handleLedgerChange(e)}
                      type="checkbox"
                      name="checkbox"
                      className="mr-2"
                    />
                    <span>Ledger</span>
                  </div>
                }
                {/* <div className="">
                  <input
                    checked={isLeadsChecked}
                    onChange={(e) => handleLeadsChange(e)}
                    type="checkbox"
                    name="checkbox"
                    className="mr-2"
                  />
                  <span>Leads</span>
                </div> */}
                {role.includes("weekly-plan") &&
                  <div className="">
                    <input
                      checked={isWeeklyPlanChecked}
                      onChange={(e) => handleWeeklyPlan(e)}
                      type="checkbox"
                      name="checkbox"
                      className="mr-2"
                    />
                    <span>Weekly Plan</span>
                  </div>
                }
                {role.includes("sample-request") &&
                  <div className="">
                    <input
                      checked={isSampleRequestChecked}
                      onChange={(e) => handleSampleRequest(e)}
                      type="checkbox"
                      name="checkbox"
                      className="mr-2"
                    />
                    <span>Sample Request</span>
                  </div>
                }
                {role.includes("ss-output-of-the-day") &&
                  <div className="">
                    <input
                      checked={isOutputDayChecked}
                      onChange={(e) => handleOutputDay(e)}
                      type="checkbox"
                      name="checkbox"
                      className="mr-2"
                    />
                    <span>Output of the day</span>
                  </div>
                }
                {role.includes("ss-output-of-the-day") &&
                  <div className="">
                    <input
                      checked={isOpenSSLeadsChecked}
                      onChange={(e) => handleOpenSSLeads(e)}
                      type="checkbox"
                      name="checkbox"
                      className="mr-2"
                    />
                    <span>Open SS Leads</span>
                  </div>
                }
              </div>
            </div>
          </main>

          {/* Table */}

          {isattendanceChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId1"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      # Date
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      # Emp. Code
                    </th>
                    <th scope="col" className="px-2 py-3 w-[20%] text-center">
                      # Name
                    </th>

                    <th scope="col" className="px-2 py-3 w-[20%] text-center">
                      # Punch In
                    </th>
                    <th scope="col" className="px-2 py-3 w-[20%] text-center">
                      # Punch Out
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      # Attendance
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      # Visit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {searchEmployeeList.length
                    ? searchEmployeeList.map((employee, index) => {
                        return (
                          <tr
                            key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2 text-center">
                              {convertDate(employee.attendancedate)}
                              {/* {employee.attendancedate} */}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {employee?.officialemail}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {/* {convertIntoCamelCase(employee.name)} */}
                              {formatName(employee.name)}
                              {/* {employee.name} */}
                            </td>

                            <td className="px-2 py-2  text-center">
                              {convertTime(employee.punchintime) ||
                                "not created"}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {convertTime(employee.punchouttime) ||
                                "not created"}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {employee.punchouttime ? (
                                <img
                                  src={submitLogo}
                                  alt="submit-logo"
                                  className="w-[18px] mx-auto  "
                                  // onClick={() => handleLedgerRequest()}
                                />
                              ) : (
                                <img
                                  src={cancelLogo}
                                  alt="submit-logo"
                                  className="w-4 mx-auto"
                                  // onClick={() => resetLedger()}
                                />
                              )}

                              {/* Hello */}
                            </td>
                            <td className="px-2 py-2   text-center">

                              <span
                                className="cursor-pointer hover:text-blue-500"
                                onClick={(e) => {
                                  if(employee?.visitCount) return
                                  attendanceVisit(e,employee?.attendancedate,employee?.mobile)
                                }}
                              >
                                {employee?.visitCount ? employee?.visitCount -1 : 'View'}
                                
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* DAR table 🚀🚀🚀 */}

          {isDarChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId2"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[15%] text-center">
                      # Name
                    </th>
                    <th scope="col" className="px-2 py-3 w-[15%] text-center">
                      # Date
                    </th>
                    {/* <th scope="col" className="px-6 py-3">
        CODE
      </th> */}
                    <th scope="col" className="px-2 py-3 w-[15%] text-center">
                      # Punch In
                    </th>
                    <th scope="col" className="px-2 py-3 w-[15%] text-center">
                      # Punch Out
                    </th>
                    {/* <th scope="col" className="px-2 py-3 w-[12%] text-center">
                      # Attendance
                    </th> */}
                    <th scope="col" className="px-2 py-3 w-[9%] text-center">
                      # Visit
                    </th>
                    <th scope="col" className="px-2 py-3 w-[9%] text-center">
                      # Total Distance
                    </th>
                    <th scope="col" className="px-2 py-3 w-[18%] text-center">
                      # Remark
                    </th>
                    <th className="w-[4%]"></th>
                  </tr>
                </thead>
                <tbody className="">
                  {employeeDarList.length
                    ? employeeDarList.map((item, index) => {
                        return (
                          <>
                            <tr
                              key={index}
                              onClick={() => {
                                if (currentIndex === index) {
                                  setCurrentIndex(null);
                                  return;
                                }
                                setCurrentIndex(index);
                                // if (showTableRow === index) {
                                //   setShowTableRow(false);
                                //   return;
                                // }
                                // setShowTableRow(index);
                              }}
                              className=" bg-[#fff] border-b  border-t-gray-400 border-gray-100 text-black cursor-pointer"
                            >
                              <td className="px-2 py-4   text-center">
                                {item.name}
                              </td>
                              <td className="px-2 py-4 text-center">
                                {" "}
                                {convertDate(item.attendancedate)}
                              </td>
                              <td className="px-2 py-4  text-center">
                                {convertTime(item.punchintime)}
                              </td>

                              <td className="px-2 py-4   text-center">
                                {convertTime(item.punchouttime) ||
                                  "not created"}
                              </td>

                              <td className="px-2 py-4   text-center">
                                {item.visits.length}
                              </td>
                              <td className="px-2 py-4   text-center">
                                {convertDistance(item.totaldistance)} KM
                              </td>
                              <td className="px-2 py-4   text-center">
                                Morning Punch in and Punch Out
                              </td>
                              <td className="px-2 py-4   text-center">
                                {currentIndex === index ? (
                                  <img
                                    src={downArrow}
                                    alt="uparrow"
                                    className="w-3"
                                  />
                                ) : (
                                  <img
                                    src={upArrow}
                                    alt="down-arrow"
                                    className="w-3"
                                  />
                                )}
                              </td>
                            </tr>

                            {/* Nested table Data */}

                            {item.visits.length ? (
                              <tr className="w-full">
                                <td
                                  key={index}
                                  colSpan={8}
                                  className="text-center w-full"
                                >
                                  <table
                                    // style={{ display: "none" }}
                                    className={`w-full text-sm text-left text-gray-400 ${
                                      currentIndex === index
                                        ? "revert"
                                        : "hidden"
                                    }`}
                                  >
                                    <thead className="text-xs text-[#1F1F1F] w-full   bg-[#EFEFEF]">
                                      <tr className="bg-gray-400 text-white w-full">
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[12%] text-center"
                                        >
                                          # Dealer/Customer Name
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[12%] text-center"
                                        >
                                          # Type
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[27%] text-center"
                                        >
                                          # Location
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[10%] text-center"
                                        >
                                          # Mobile
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[15%] text-center"
                                        >
                                          # Address
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[9%] text-center"
                                        >
                                          # Punch In
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[9%] text-center"
                                        >
                                          # Punch Out
                                        </th>

                                        {/* <th
                                          scope="col"
                                          className="px-2 py-3 w-[9%] text-center"
                                        >
                                          # Distance
                                        </th> */}
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[35%] text-center"
                                        >
                                          # SS Remarks
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[35%] text-center"
                                        >
                                          # MCA/MSP Remarks
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[35%] text-center"
                                        >
                                          # W Aux Remarks
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[4%] text-center"
                                        >Status</th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[4%] text-center"
                                        >Activity</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {item.visits.length
                                        ? item.visits.map((item, index) => {
                                            return (
                                              <tr
                                                key={index}
                                                className=" bg-gray-200 border-b border-gray-100 text-black w-full"
                                              >
                                                <td className="px-2 py-4   text-center">
                                                  {item.dealer}
                                                </td>
                                                <td className="px-2 py-4   text-center">
                                                  {item.type}
                                                </td>
                                                <td className="px-2 py-4 text-center">
                                                  {item.address}
                                                </td>

                                                <td className="px-2 py-4   text-center">
                                                  {item.dealermobile}
                                                </td>

                                                <td className="px-2 py-4   text-center">
                                                  {item.dealerlocation}
                                                </td>
                                                <td className="px-2 py-4  text-center">
                                                  {convertTime(
                                                    item.punchintime
                                                  )}
                                                </td>

                                                <td className="px-2 py-4   text-center">
                                                  {convertTime(
                                                    item.punchouttime
                                                  )}
                                                </td>
                                                
                                                {/* <td className="px-2 py-4   text-center">
                                                  {item.distance || "00"} KM
                                                </td> */}
                                                <td
                                                  className="px-2 py-4  text-center"
                                                >
                                                  {item.extra5}
                                                </td>
                                                <td
                                                  className="px-2 py-4  text-center"
                                                >
                                                  {item.extra4}
                                                </td>
                                                <td
                                                  className="px-2 py-4  text-center"
                                                >
                                                  {item.remark}
                                                </td>
                                                <td
                                                  className="px-2 py-4  text-center"
                                                >
                                                  <span
                                                    onClick={() => {
                                                      updateDarStatus(item?.visitid, item?.status? item?.status: 'Open')
                                                    }}
                                                    className="cursor-pointer border border-[#ccc] px-4 py-2 w-32 rounded-lg bg-white block mx-auto"
                                                  >
                                                    {
                                                      // getDarNewStatus ? getDarNewStatus :
                                                      item?.status ? item?.status : 'Open'
                                                    }
                                                  </span>
                                                </td>
                                                <td
                                                  className="px-2 py-4  text-center"
                                                >
                                                  <button
                                                    onClick={(e) => activityDarViewFun(item?.visitid, item?.item)}
                                                    className="bg-[#f37022] text-white px-3 py-1 rounded-md"
                                                  >View</button>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : ""}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* Odometer 🚀🚀🚀 */}

          {isImprestChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId3"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      # Date
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      # Emp. Code
                    </th>
                    <th scope="col" className="px-2 py-3 w-[16%] text-center">
                      # Name
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      # PunchIn Reading
                    </th>
                    <th scope="col" className="px-2 py-3 w-[12%] text-center">
                      # PunchIn Remark
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      # PunchOut Reading
                    </th>
                    <th scope="col" className="px-2 py-3 w-[12%] text-center">
                      # PunchOut Remark
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      # Total Reading
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      # Google Reading
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeOdometerList.length
                    ? employeeOdometerList.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2   text-center">
                              {handleOdometerDate(item.date)}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {item.empcode}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {convertIntoCamelCase(item.name)}
                            </td>
                            <td className="px-2 py-2  text-center flex flex-col justify-center items-center">
                              <a
                                href={config.ipAddress + item.punchinimage}
                                target="blank"
                                className="py-1 text-blue-400 inline-block w-fit "
                              >
                                {item.punchinreading}
                              </a>
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item.punchinremark}
                            </td>

                            <td className="px-2 py-2  text-center flex flex-col justify-center items-center">
                              <a
                                href={
                                  getPunchOutDetails(
                                    item.punchinid,
                                    item.punchoutid,
                                    item.punchoutreading,
                                    item.punchoutimage,
                                    item.punchoutremark
                                  ).punchOutImageUrl !== ""
                                    ? getPunchOutDetails(
                                        item.punchinid,
                                        item.punchoutid,
                                        item.punchoutreading,
                                        item.punchoutimage,
                                        item.punchoutremark
                                      ).punchOutImageUrl
                                    : undefined
                                }
                                target="blank"
                                className="py-1 text-blue-400 inline-block w-fit "
                              >
                                {
                                  getPunchOutDetails(
                                    item.punchinid,
                                    item.punchoutid,
                                    item.punchoutreading,
                                    item.punchoutimage,
                                    item.punchoutremark
                                  ).punchOutValue
                                }
                              </a>
                            </td>

                            <td className="px-2 py-2 text-center">
                              {
                                getPunchOutDetails(
                                  item.punchinid,
                                  item.punchoutid,
                                  item.punchoutreading,
                                  item.punchoutimage,
                                  item.punchoutremark
                                ).punchOutRemarkUpdated
                              }
                            </td>
                            <td className="px-2 py-2   text-center">
                              {getOdoTotalReading(
                                item.punchinreading,
                                item.punchoutreading
                              ) || "0"}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {Number(item.apireading).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* leave request 🚀🚀🚀 */}

          {isLeaveChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId4"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      # Date
                    </th>
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      # Emp. Code
                    </th>
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      # Name
                    </th>
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      # Remark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeLeaveList.length
                    ? employeeLeaveList.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2 text-center">
                              {convertDate(item.created_at)}
                              {/* 20/5/2023 */}
                              {/* {employee.attendancedate} */}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.empcode}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {convertIntoCamelCase(item.name)}
                              {/* {employee.name} */}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {/* {convertTime(employee.punchouttime) || "null"} */}
                              {item.reason}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* Sample request 🚀🚀🚀 */}

          {isSampleChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId5"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[30%] text-center">
                      # Date
                    </th>
                    <th scope="col" className="px-2 py-3 w-[30%] text-center">
                      # Name
                    </th>

                    <th scope="col" className="px-2 py-3 w-[40%] text-center">
                      # Remark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeSampleList.length
                    ? employeeSampleList.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2 text-center">
                              {/* {convertDate(employee.attendancedate)} */}
                              {convertDate(item.created_at)}
                              {/* {employee.attendancedate} */}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {convertIntoCamelCase(item.name)}
                              {/* {employee.name} */}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {/* {convertTime(employee.punchouttime) || "null"} */}
                              {item.reason}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* ledger reauest 🚀🚀🚀 */}

          {isLedgerChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId6"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      # Name
                    </th>
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      # Dealer
                    </th>

                    {/* <th scope="col" className="px-6 py-3">
        CODE
      </th> */}

                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      # Form
                    </th>
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      # To
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeLedgerList.length
                    ? employeeLedgerList.map((item, index) => {
                        return (
                          <tr
                            // key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2   text-center">
                              {convertIntoCamelCase(item.name)}
                              {/* {employee.name} */}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {/* {convertDate(employee.attendancedate)} */}
                              {item.dealername}
                              {/* {employee.attendancedate} */}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {/* {convertTime(employee.punchouttime) || "null"} */}
                              {convertDate(item.datefrom)}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {/* {convertTime(employee.punchouttime) || "null"} */}
                              {convertDate(item.dateto)}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* leads table 🚀🚀🚀 */}

          {isLeadsChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                  <th scope="col" className="px-2 py-3 text-center">
                      # Assignee
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Name
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # State
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # City
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Mobile
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Requirement
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Order Status
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Dealer
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Value
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Remark
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Created At
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Updated At
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeLeadsList.length
                    ? employeeLeadsList.map((item, index) => {
                        return (
                          <tr
                            // key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2   text-center">
                              {item?.assigneemobile ? convertIntoCamelCase(options?.find(el=>el?.value===item?.assigneemobile)?.label) : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.name ? convertIntoCamelCase(item?.name) :"-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.state ? convertIntoCamelCase(item?.state) :"-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.city ? convertIntoCamelCase(item?.city) : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.mobile ? item?.mobile : "-"}
                            </td>
                            <td className="px-2 py-2 text-center" title={item?.extra4}>
                            {item?.extra4?.length >= 31 ? (`${item?.extra4.substring(0,31)}...`) : item?.extra4 ? item?.extra4 : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.extra1 ? item?.extra1 : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.extra2 ? item?.extra2 : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.extra3 ? item?.extra3 : "-"}
                            </td>
                            <td className="px-2 py-2 text-center"  title={item?.remark}>
                            {item?.remark?.length >= 31 ? (`${item?.remark.substring(0,31)}...`) : item?.remark ? item?.remark : "-"}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {convertDate(item?.created_at)}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {item?.updated_at ? convertDate(item?.updated_at) : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                            <Chip text={item?.extra5==="1" ? "Open" : "Closed"} textColor={item?.extra5==="1" ? "#005ba9" : "#a12c28"} bg={item?.extra5==="1" ? "#eef8fc" :"#fdebed"}/>
                              {/* {item?.extra5==="1" ? "Open" : "Closed"} */}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* Weekly plan table */}

          {isWeeklyPlanChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId8"
                className="w-full text-sm text-left text-gray-400"
              >
                <thead className="text-xs text-[#1F1F1F] bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[13%] text-center"># Name</th>
                    <th scope="col" className="px-2 py-3 w-[13%] text-center"># Date</th>
                    <th scope="col" className="px-2 py-3 w-[13%] text-center"># Imprest Needed</th>
                    <th scope="col" className="px-2 py-3 w-[13%] text-center"># Cleared</th>
                    <th scope="col" className="px-2 py-3 w-[13%] text-center"># Number of Outstation Days</th>
                    <th scope="col" className="px-2 py-3 w-[13%] text-center"># Area of Operation</th>
                    <th scope="col" className="px-2 py-3 w-[13%] text-center"># Weekly Imprest Request</th>
                    <th scope="col" className="px-2 py-3 w-[4%] text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  {employeeWeeklyList.length ? 
                    employeeWeeklyList.map((item, index) => {
                      return(
                        <>
                          <tr
                            onClick={() => {
                              if (currentIndex === index) {
                                setCurrentIndex(null);
                                return;
                              }
                              setCurrentIndex(index);

                              // if (showTableRow === index) {
                                //   setShowTableRow(false);
                                //   return;
                                // }
                                // setShowTableRow(index);
                            }}
                            className=" bg-[#fff] border-b  border-t-gray-400 border-gray-100 text-black cursor-pointer"
                          >
                            <td className="px-2 py-4   text-center">{item?.name}</td>
                            <td className="px-2 py-4   text-center">{convertDate(item?.creationdate)}</td>
                            <td className="px-2 py-4   text-center">{item?.imprest_needed}</td>
                            <td className="px-2 py-4   text-center">{item?.previous_imprest_cleared}</td>
                            <td className="px-2 py-4   text-center">{item?.number_of_outstation_days}</td>
                            <td className="px-2 py-4   text-center">{item?.area_of_operation}</td>
                            <td className="px-2 py-4   text-center">{item?.weekly_imprest_request}</td>
                            <td className="px-2 py-4   text-center">
                              {currentIndex === index ? (
                                <img
                                  src={downArrow}
                                  alt="uparrow"
                                  className="w-3"
                                />
                              ) : (
                                <img
                                  src={upArrow}
                                  alt="down-arrow"
                                  className="w-3"
                                />
                              )}
                            </td>
                          </tr>
                          {item?.plans?.length ? 
                            (
                              <tr>
                                <td colSpan={8}>
                                  <table className={`w-full text-sm text-left text-gray-400 ${currentIndex === index
                                        ? "revert"
                                        : "hidden"
                                    }`}>
                                    <thead className="text-xs text-[#1F1F1F] bg-[#EFEFEF]">
                                      <tr className="bg-gray-400 text-white">
                                        <th scope="col" className="px-2 py-3 text-center"># Date</th>
                                        <th scope="col" className="px-2 py-3 text-center"># Week Days</th>
                                        <th scope="col" className="px-2 py-3 text-center"># Area of Operation</th>
                                        <th scope="col" className="px-2 py-3 text-center"># SS CWA (Orange) Plan</th>
                                        <th scope="col" className="px-2 py-3 text-center"># SS MCA (Yellow) Plan	</th>
                                        <th scope="col" className="px-2 py-3 text-center"># SS Stan (Blue) / Stavinox (Green) Plan</th>
                                        <th scope="col" className="px-2 py-3 text-center"># Stavinox New Amount Plan</th>
                                        <th scope="col" className="px-2 py-3 text-center"># New MCA / Industrial Customer Visit Plan	</th>
                                        <th scope="col" className="px-2 py-3 text-center"># Remarks</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.plans.length ?
                                      item?.plans.map((item, index)=>{
                                        return(
                                          <tr
                                            key={index}
                                            className=" bg-gray-200 border-b border-gray-100 text-black w-full"
                                          >
                                            <td className="px-2 py-2 text-center">{convertDate(item?.plandate)}</td>
                                            <td className="px-2 py-2 text-center bg-gray-200 text-black">{item?.weekday}</td>
                                            <td className="px-2 py-2 text-center">{item?.area_of_operation}</td>
                                            <td className="px-2 py-2 text-center">{item?.ss_cwa_plan}</td>
                                            <td className="px-2 py-2 text-center">{item?.mca_plan}</td>
                                            <td className="px-2 py-2 text-center">{item?.stavinox_plan}</td>
                                            <td className="px-2 py-2 text-center">{item?.dealer_customer_target}</td>
                                            <td className="px-2 py-2 text-center">{item?.industrial_customer_visit_plan}</td>
                                            <td className="px-2 py-2 text-center">{item?.remarks}</td>
                                          </tr>
                                        )
                                      })
                                      :
                                      ''
                                      }
                                    
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )
                            :
                            ("")
                          }
                        </>
                      )
                    })
                    :
                    ''
                  }
                  

                </tbody>
              </table>
              
            </div>
          )}

          {/* Sample Request */}
          {isSampleRequestChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId9"
                className="w-full text-sm text-left text-gray-400"
              >
                <thead className="text-xs text-[#1F1F1F] bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[12%] text-center"># Date</th>
                    <th scope="col" className="px-2 py-3 w-[12%] text-center"># Name</th>
                    <th scope="col" className="px-2 py-3 w-[12%] text-center"># Dealer Type</th>
                    <th scope="col" className="px-2 py-3 w-[12%] text-center"># Dealer Name</th>
                    <th scope="col" className="px-2 py-3 w-[12%] text-center"># Address</th>
                    <th scope="col" className="px-2 py-3 w-[12%] text-center"># State</th>
                    <th scope="col" className="px-2 py-3 w-[12%] text-center"># City</th>
                    <th scope="col" className="px-2 py-3 w-[12%] text-center"># Language</th>
                    <th scope="col" className="px-2 py-3 w-[4%] text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  {sampleRequestList.length ? 
                    sampleRequestList.map((item, index) => {
                      return(
                        <>
                          <tr
                            onClick={() => {
                              if (currentIndex === index) {
                                setCurrentIndex(null);
                                return;
                              }
                              setCurrentIndex(index);

                              // if (showTableRow === index) {
                                //   setShowTableRow(false);
                                //   return;
                                // }
                                // setShowTableRow(index);
                            }}
                            className=" bg-[#fff] border-b  border-t-gray-400 border-gray-100 text-black cursor-pointer"
                          >
                            <td className="px-2 py-4   text-center">{convertDate(item?.creationdate)}</td>
                            <td className="px-2 py-4   text-center">{item?.name}</td>
                            <td className="px-2 py-4   text-center">{item?.existing}</td>
                            <td className="px-2 py-4   text-center">{item?.dealername}</td>
                            <td className="px-2 py-4   text-center">{item?.address}</td>
                            <td className="px-2 py-4   text-center">{item?.state}</td>
                            <td className="px-2 py-4   text-center">{item?.city}</td>
                            <td className="px-2 py-4   text-center">{item?.language}</td>
                            <td className="px-2 py-4   text-center">
                              {currentIndex === index ? (
                                <img
                                  src={downArrow}
                                  alt="uparrow"
                                  className="w-3"
                                />
                              ) : (
                                <img
                                  src={upArrow}
                                  alt="down-arrow"
                                  className="w-3"
                                />
                              )}
                            </td>
                          </tr>
                          {item?.samples?.length ? 
                            (
                              <tr>
                                <td colSpan={9}>
                                  <table className={`w-full text-sm text-left text-gray-400 ${currentIndex === index
                                        ? "revert"
                                        : "hidden"
                                    }`}>
                                    <thead className="text-xs text-[#1F1F1F] bg-[#EFEFEF]">
                                      <tr className="bg-gray-400 text-white">
                                        <th scope="col" className="px-2 py-3 text-center"># Item Description</th>
                                        <th scope="col" className="px-2 py-3 text-center"># Item Diamension</th>
                                        <th scope="col" className="px-2 py-3 text-center"># Total Quantity Required</th>
                                        <th scope="col" className="px-2 py-3 text-center"># Quantity Per Packet</th>
                                        {role.includes("sample-request") ?
                                          <>
                                            <th scope="col" className="px-2 py-3 text-center"># Status</th>
                                            <th scope="col" className="px-2 py-3 text-center"># Activity</th>
                                          </>
                                          :
                                          ''
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.samples.length ?
                                      item?.samples.map((sample, index)=>{
                                        return(
                                          <tr
                                            key={index}
                                            className=" bg-gray-200 border-b border-gray-100 text-black w-full"
                                          >
                                            <td className="px-2 py-2 text-center">{sample?.item}</td>
                                            <td className="px-2 py-2 text-center">{sample?.dimension}</td>
                                            <td className="px-2 py-2 text-center">{sample?.totalquantity}</td>
                                            <td className="px-2 py-2 text-center">{sample?.quantityperpacket}</td>
                                            {role.includes("sample-request") ?
                                              <>
                                              <td className="px-2 py-2 text-center">
                                                <span
                                                  onClick={() => {
                                                    const sampleObj = item?.samples
                                                    console.log('item name',sampleObj)
                                                    updateStatus(sample?.itemid, sample?.status? sample?.status: 'New', sampleObj)
                                                    console.log('status sdd',sample?.status)
                                                  }}
                                                  className="cursor-pointer border border-[#ccc] px-4 py-2 w-32 rounded-lg bg-white block mx-auto"
                                                >
                                                  {sample?.status ? sample?.status : 'New'}
                                                  {/* {
                                                    getNewStatus ? getNewStatus :
                                                    sample?.status ? sample?.status : 'New'
                                                  } */}
                                                </span>
                                              </td>
                                              <td className="px-2 py-2 text-center">
                                                <button
                                                  // onClick={(e) => activityViewFun(sample?.itemid, sample?.item)}
                                                  onClick={(e) => allActivity()}
                                                  className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600"
                                                >View</button>
                                              </td>
                                              </>
                                              :
                                              ''
                                            }
                                          </tr>
                                        )
                                      })
                                      :
                                      ''
                                      }
                                    
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )
                            :
                            ("")
                          }
                        </>
                      )
                    })
                    :
                    ''
                  }
                  

                </tbody>
              </table>
              
            </div>
          )}

          {/* Leads table for exel download */}
            <div className="my-[19px]  overflow-x-auto  shadow-lg" style={{display:'none'}}>
              <table
                id="tableId7"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                  <th scope="col" className="px-2 py-3 text-center">
                      # Assignee
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Name
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # State
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # City
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Mobile
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Requirement
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Order Status
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Dealer
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Value
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Remark
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Created At
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Updated At
                    </th>
                    <th scope="col" className="px-2 py-3 text-center">
                      # Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeLeadsList.length
                    ? employeeLeadsList.map((item, index) => {
                        return (
                          <tr
                            // key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2   text-center">
                              {item?.assigneemobile ? convertIntoCamelCase(options?.find(el=>el?.value===item?.assigneemobile)?.label) : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.name ? convertIntoCamelCase(item?.name) :"-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.state ? convertIntoCamelCase(item?.state) :"-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.city ? convertIntoCamelCase(item?.city) : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.mobile ? item?.mobile : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                            {item?.extra4}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.extra1 ? item?.extra1 : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.extra2 ? item?.extra2 : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item?.extra3 ? item?.extra3 : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                            {item?.remark}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {convertDate(item?.created_at)}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {item?.updated_at ? convertDate(item?.updated_at) : "-"}
                            </td>
                            <td className="px-2 py-2 text-center">
                            <Chip text={item?.extra5==="1" ? "Open" : "Closed"} textColor={item?.extra5==="1" ? "#005ba9" : "#a12c28"} bg={item?.extra5==="1" ? "#eef8fc" :"#fdebed"}/>
                              {/* {item?.extra5==="1" ? "Open" : "Closed"} */}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          {/* )}           */}

          {/* SS Output of the day 🚀🚀🚀 */}

          {isOutputDayChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
            <table
              id="tableId10"
              className="w-full text-sm text-left text-gray-400 "
            >
              <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                <tr className="bg-gray-500 text-white">
                  <th className="px-2 py-3 w-[85px] min-w-[85px] max-w-[85px] text-center">
                    # Date
                  </th>
                  {/* <th scope="col" className="px-2 py-3 w-[10%] text-center">
                    # Emp. Code
                  </th> */}
                  <th className="px-2 py-3 w-[150px] min-w-[150px] max-w-[150px] text-left">
                    # Name
                  </th>

                  {/* <th scope="col" className="px-2 py-3 w-[15%] text-center">
                    # Order Collected
                  </th>
                  <th scope="col" className="px-2 py-3 w-[15%] text-center">
                    # New SS Dealer Visited
                  </th>
                  <th scope="col" className="px-2 py-3 w-[15%] text-center">
                    # New SS Fabricator Demo
                  </th>
                  <th scope="col" className="px-2 py-3 w-[15%] text-center">
                    # New SS Retailer Visited
                  </th> */}


                  <th className="px-2 py-3 w-[150px] min-w-[150px] max-w-[150px] text-left bg-[#555555]">
                    # Customer Demo/Order
                  </th>
                  <th className="px-2 py-3  w-[150px] min-w-[150px] max-w-[150px] text-left bg-[#555555]">
                    # Customer Product
                  </th>
                  <th className="px-2 py-3  w-[150px] min-w-[150px] max-w-[150px] text-left bg-[#555555]">
                    # Customer Remark
                  </th>

                  <th className="px-2 py-3  w-[150px] min-w-[150px] max-w-[150px] text-left bg-[#777777]">
                    # Retailer Order
                  </th>
                  <th className="px-2 py-3  w-[150px] min-w-[150px] max-w-[150px] text-left bg-[#777777]">
                    # Retailer Product
                  </th>
                  <th className="px-2 py-3  w-[150px] min-w-[150px] max-w-[150px] text-left bg-[#777777]">
                    # Retailer Remark
                  </th>

                  <th className="px-2 py-3  w-[150px] min-w-[150px] max-w-[150px] text-left bg-[#999999]">
                    # Dealer Order
                  </th>
                  <th className="px-2 py-3  w-[150px] min-w-[150px] max-w-[150px] text-left bg-[#999999]">
                    # Dealer Product
                  </th>
                  <th className="px-2 py-3  w-[150px] min-w-[150px] max-w-[150px] text-left bg-[#999999]">
                    # Dealer Remark
                  </th>

                </tr>
              </thead>
              <tbody>
                {employeeOutputList.length
                  ? employeeOutputList.map((employee, index) => {
                      return (
                        <tr
                          key={index}
                          className={` ${
                            index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                          } border-b border-gray-100 text-black`}
                        >
                          <td className="px-2 py-2 text-left">
                            {convertDate(employee.created_at)}
                          </td>
                          
                          <td className="px-2 py-2   text-left">
                            {formatName(employee.name)}
                          </td>

                          {/* <td className="px-2 py-2  text-center">
                            {employee.order_collected === "null" ? "0" : employee.order_collected}
                          </td>

                          <td className="px-2 py-2   text-center">
                            {employee.new_ss_dealer_visited === "null" ? "0" : employee.new_ss_dealer_visited}
                          </td>
                          <td className="px-2 py-2   text-center">
                            {employee.new_ss_fabricator_demo === "null" ? "0" : employee.new_ss_fabricator_demo}
                          </td>
                          <td className="px-2 py-2   text-center">
                            {employee.new_ss_retailer_visited === "null" ? "0" : employee.new_ss_retailer_visited}
                          </td> */}

                          <td scope="col" className="px-2 py-3 w-[15%] text-left"> {/* bg-[#e7e6e6] */}
                            {employee?.customertype}
                          </td>
                          <td scope="col" className="px-2 py-3 w-[15%] text-left">
                            {employee?.customerproduct}
                          </td>
                          <td scope="col" className="px-2 py-3 w-[15%] text-left">
                            {employee?.customerremark}
                          </td>

                          <td scope="col" className="px-2 py-3 w-[15%] text-left"> {/* bg-[#e5dbdb] */}
                            {employee?.retailertype}
                          </td>
                          <td scope="col" className="px-2 py-3 w-[15%] text-left">
                            {employee?.retailerproduct}
                          </td>
                          <td scope="col" className="px-2 py-3 w-[15%] text-left">
                            {employee?.retailerremark}
                          </td>

                          <td scope="col" className="px-2 py-3 w-[15%] text-left"> {/* bg-[#d5d5d5] */}
                            {employee?.dealertype}
                          </td>
                          <td scope="col" className="px-2 py-3 w-[15%] text-left">
                            {employee?.dealerproduct}
                          </td>
                          <td scope="col" className="px-2 py-3 w-[15%] text-left">
                            {employee?.dealerremark}
                          </td>

                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
          )}


          {/* Open SS Leads data 🚀🚀🚀 */}

          {isOpenSSLeadsChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
            <table
              id="tableId10"
              className="w-full text-sm text-left text-gray-400 "
            >
              <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                <tr className="bg-gray-500 text-white">
                  <th scope="col" className="px-2 py-3 w-[10%] text-center">
                    # Date
                  </th>
                  {/* <th scope="col" className="px-2 py-3 w-[10%] text-center">
                    # Emp. Code
                  </th> */}
                  <th scope="col" className="px-2 py-3 w-[20%] text-center">
                    # Dealer Name
                  </th>
                  <th scope="col" className="px-2 py-3 w-[20%] text-center">
                    # Competitor Brand Name
                  </th>
                  <th scope="col" className="px-2 py-3 w-[15%] text-center">
                    # Buying From
                  </th>
                  <th scope="col" className="px-2 py-3 w-[15%] text-center">
                    # SS Remark
                  </th>
                  <th scope="col" className="px-2 py-3 w-[15%] text-center">
                    # Remark
                  </th>
                </tr>
              </thead>
              <tbody>
                {openSSLeadsList.length
                  ? openSSLeadsList.map((lead, index) => {
                      return (
                        <>
                        {lead?.visits.length ?
                          lead?.visits.filter((filterVisit) => filterVisit?.visitoption.toLowerCase() === "competitor")
                          .map( (visit) => (
                            <>
                              <tr
                                key={index}
                                className={` ${
                                  index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                                } border-b border-gray-100 text-black`}
                              >
                                <td className="px-2 py-2 text-center">
                                  {visit?.visitdate}
                                  {/* {employee.attendancedate} */}
                                </td>
                                {/* <td className="px-2 py-2 text-center">
                                  {employee?.officialemail}
                                </td> */}
                                <td className="px-2 py-2   text-center">
                                  {visit?.dealer}
                                </td>
                                <td className="px-2 py-2   text-center">
                                  {visit?.competitorbrandname}
                                </td>

                                <td className="px-2 py-2  text-center">
                                  {visit?.buyingfrom}
                                </td>

                                <td className="px-2 py-2   text-center">
                                  {visit?.extra5}
                                </td>
                                <td className="px-2 py-2   text-center">
                                  <span
                                    onClick={() => {
                                      updateSSLeadStatus(visit?.visitid)
                                    }}
                                    className="cursor-pointer border border-[#ccc] px-4 py-2 w-32 rounded-lg bg-white block mx-auto"
                                  >
                                    Add Remark
                                  </span>
                                </td>
                              </tr>
                            </>
                          ))
                          :
                          ''
                        }
                        
                        </>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
          )}


        </section>
      </div>

      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}
      <LeadModal showModal={showModal} setShowModal={setShowModal} refreshLeads={refreshLeads} setRefreshLeads={setRefreshLeads}/>
      
      <Drawer
        anchor="right"
        hideBackdrop={false}
        open={sampleActivity}
        onClose={closeForm}
      >
        <SampleRequestActivity
          closeForm={closeForm}
          // id={sampleId}
          // sampleName={sampleName}
          getActivityData={getActivityData}
        ></SampleRequestActivity>
      </Drawer>
      
      <Drawer
        anchor="right"
        hideBackdrop={false}
        open={statusRemark}
        onClose={closeForm}
      >
        <StatusRemark
          closeForm={closeForm}
          id={sampleId}
          sampleObj={sampleObj}
          oldstatus={oldStatus}
          getNewStatus={setGetNewStatus}
        ></StatusRemark>
      </Drawer>

      <Drawer
        anchor="right"
        hideBackdrop={false}
        open={ssLeadRemark}
        onClose={closeForm}
      >
        <SSLeadRemarkAdmin
          closeForm={closeForm}
          id={ssLeadRemarkId}
          // getNewStatus={setGetNewStatus}
        ></SSLeadRemarkAdmin>
      </Drawer>

      <Drawer
        anchor="right"
        hideBackdrop={false}
        open={darRemark}
        onClose={closeForm}
      >
        <DARRemark
          closeForm={closeForm}
          id={visitId}
          darOldstatus={darOldStatus}
          getDarNewStatus={setGetDarNewStatus}
          updateDarVisit={updateDarVisit}
        ></DARRemark>
      </Drawer>
      <Drawer
        anchor="right"
        hideBackdrop={false}
        open={darActivity}
        onClose={closeForm}
      >
        <DARActivity
          closeForm={closeForm}
          id={visitId}
          sampleName={sampleName}
        ></DARActivity>
      </Drawer>
    </div>
    
  );
}

export default Report;
