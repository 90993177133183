import React from "react";
import superonLogo from "../../assets/superonlogo.png";
import { NavLink, useNavigate } from "react-router-dom";

function Navbar() {
  const adminEmail = localStorage.getItem("superonAdmin");
  const role = JSON.parse(localStorage.getItem('role')) || []
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem("superonAdmin");
    localStorage.removeItem("role");
    navigate("/");
  };

  return (
    <div>
      <main className="h-[12vh] bg-[#ed691d]">
        <nav className="flex justify-between items-center h-full px-10">
          <div>
            <img src={superonLogo} alt="superon-logo" width="130" />
          </div>
          <ul className="flex gap-10 text-white text-xl">
            {role.includes("dashboard") &&
              <li>
                <NavLink to="/dashboard" className="">
                  All User
                </NavLink>
              </li>
            }
            {/* {role.includes("report") && */}
              <li>
                <NavLink to="/report">Report</NavLink>
              </li>
            {/* } */}
            {role.includes("upload") &&
              <li>
                <NavLink to="/upload">Upload</NavLink>
              </li>
            }
            <li title={adminEmail} onClick={() => handleLogOut()}>
              <NavLink>Logout</NavLink>
            </li>
          </ul>
        </nav>
      </main>
    </div>
  );
}

export default Navbar;
