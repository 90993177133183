import React, { useEffect, useState } from 'react'
import ArrowLeft from "../../assets/keyboard_arrow_left.svg"
import Loader from "../../assets/loading2.gif";
import "./Activity.css"
import axios from 'axios'
import config from '../utils/config'
import { Await } from 'react-router-dom'
import SampleRequestActivity from './SampleRequestActivity'
import { Drawer } from '@mui/material'

const SSLeadRemarkAdmin = ({closeForm,id}) => {
     const [statusData,setStatusData] = useState({
        itemid: id,
        remark: '',
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setStatusData((prev) => ({
          ...prev,
          [name]: value,
        }));
        setErrObj({
            [name]: false
        })
    };
    const [submitLoader,setSubmitLoader] = useState(false)
    const [errObj,setErrObj] = useState({})
    const role = JSON.parse(localStorage.getItem('role')) || []
    const validate = () => {
        const error ={};
        if(!statusData?.remark) {
            error.remark = true
        }
        setErrObj(error)
        return error
        // return Object.keys(errObj).length === 0
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitLoader(true)
        const payload = {...statusData, visitid: id, email: localStorage.getItem("superonAdmin")};
        const errors = validate()
        setErrObj(errors)
        console.log('form',payload)
        if(Object.keys(errors).length !== 0) {
            console.log('show error')
            setSubmitLoader(false)
            return
        }
        console.log('suessfully submit')
        // return
        try {
            console.log('status form',payload);
            // return
            const res = await axios.post(config.ipAddress + `/dealer/addsalesteamcompteitorremark`, payload)
            console.log('sdfd',res?.data?.message)
            closeForm(true)
            setStatusData({
                ...statusData,
                remark: ''
            })

            setSubmitLoader(false)
        } catch (error) {
            setSubmitLoader(false)
        }
    };
      
    return (
        <>
        <div className='drawer'>
            <div className="drawer-header">
                <div
                    className="flex flex-wrap justify-between items-center cursor-pointer drawer-heading"
                >
                    <img
                        src={ArrowLeft}
                        className="mr-3"
                        onClick={() => closeForm()}
                    />
                    <span
                        onClick={() => closeForm()}
                        className='mr-auto'
                    >SS Lead Remark</span>
                </div>
            </div>
            <div className="drawer-body">
                <div className="drawer-form">
                    <div className="flex flex-wrap justify-between">
                        <label>Remark</label>
                        <div className="property">
                            <textarea
                                name="remark"
                                value={statusData.remark}
                                onChange={handleChange}
                                rows={5}
                                placeholder='Type here'
                                className={`${errObj?.remark ? 'error-field' : ''}`}
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="drawer-footer relative">
                <button
                onClick={() => closeForm()}
                className="btn mr-2"
                >
                Close
                </button>
                <button
                // className={`btn-drawer ${submitLoader ? "!cursor-default":"!cursor-pointer"}`}
                className='btn btn-primary'
                onClick={handleSubmit}
                disabled={submitLoader ? true: false}
                >
                {submitLoader ? <img src={Loader} width="54" className='-mt-[10px]' /> : 'Submit'}
                </button>
            </div>
        </div>
        </>
    )
}

export default SSLeadRemarkAdmin
